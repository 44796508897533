<template>
  <div class="topbar-item">
    <div
      id="kt_quick_user_toggle"
      class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
    >
      <span
        class="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1"
        v-if="user_info.data.length === 0"
      >
        {{ $t("AUTH.GENERAL.GUESS") }}
      </span>
      <span
        class="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4"
        v-if="user_info.data.length !== 0"
      >
        {{ user_info.data[0].name }}
      </span>
      <span class="symbol symbol-35">
        <span
          class="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30"
        >
          S
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user_not_login"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
      v-if="user_info.data.length === 0"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          {{ $t("AUTH.USER_PROFILE.TITLE") }}
          <small class="text-muted font-size-sm ml-2">
            {{ $t("AUTH.GENERAL.PLEASE_LOGIN") }}
          </small>
        </h3>
      </div>
      <!--end::Header-->
      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <div class="d-flex align-items-center mt-5">
          <b-button-group>
            <b-button variant="success" @click="onLogin">{{
              $t("AUTH.LOGIN.BUTTON")
            }}</b-button>
            <b-button variant="info" @click="onRegister">{{
              $t("AUTH.GENERAL.SIGNUP_BUTTON")
            }}</b-button>
          </b-button-group>
        </div>
      </perfect-scrollbar>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
      v-if="user_info.data.length !== 0"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          {{ $t("AUTH.USER_PROFILE.TITLE") }}
          <small class="text-muted font-size-sm ml-2">
            <!-- 12 {{ $t("AUTH.USER_PROFILE.MESSAGE") }} -->
          </small>
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column" v-if="user_info != null">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              v-if="user_info != null"
            >
              {{ user_info.data[0].name }}
            </a>
            <div class="text-muted mt-1" v-if="user_info != null">
              {{ user_info.data[0].email }}
            </div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ user_info.data[0].employee_email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>

        <div class="d-flex align-items-center mt-5">
          
          <button class="btn btn-light btn-block">
            <router-link
              to="/profile">
              <span class="navi-link p-0 pb-2">
                    <span class="navi-icon mr-1">
                      <span class="svg-icon svg-icon-lg svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          src="media/svg/icons/General/User.svg"
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <span class="navi-text text-muted text-hover-primary">
                      {{ $t("AUTH.USER_PROFILE.MY_PROFILE") }}
                    </span>
                  </span>
            </router-link>
          </button>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning"
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success"
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger"
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info"
        }
      ]
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => window.location.reload());
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    onLogin() {
      this.$router.push({ name: "login" });
    },
    onRegister() {
      this.$router.push({ name: "register" });
    }
  },
  computed: {
    user_info() {
      if (localStorage.getItem("user_info") === null)
        return {
          data: []
        };
      else {
        return JSON.parse(localStorage.getItem("user_info"));
      }
    },
    picture() {
      return process.env.BASE_URL + "media/users/default.jpg";
    },
    ...mapState({
      user: state => state.auth.user.data
    })
  }
};
</script>
