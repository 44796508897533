<template>
  <ul class="menu-nav">
    <li v-if="isMobile()===true"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{
        'menu-item-active': hasActiveChildren('/complaint-data')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> {{ $t("MENU.COMPLAINT_DATA") }} </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu">
          <ul class="menu-content">
            <li class="menu-item">
              <router-link
                  to="/dashboard"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
              <a :href="href" class="menu-link menu-heading menu-toggle"
                 :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                 @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">
                        {{ $t("MENU.COMPLAINT_DASHBOARD") }}
                      </span>
              </a>
            </router-link>
            <router-link
                to="/complaint-data/data-consultation"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <a :href="href" class="menu-link menu-heading menu-toggle"
                   :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                   @click="navigate">
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">
                        {{ $t("MENU.COMPLAINT_CONSULTATION") }}
                      </span>
                </a>
            </router-link>
            <router-link
                to="/complaint-data/complaint-receipt-data"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <a :href="href" class="menu-link menu-heading menu-toggle"
                   :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                   @click="navigate">
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">
                        {{ $t("MENU.COMPLAINT_RECEIPT_DATA") }}
                      </span>
                </a>
            </router-link>
            <router-link
                to="/complaint-data/complaint-data-application"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <a :href="href" class="menu-link menu-heading menu-toggle"
                   :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                   @click="navigate">
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">
                        {{ $t("MENU.COMPLAINT_DATA_APPLICATION") }}
                      </span>
                </a>
            </router-link>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li v-if="isMobile()===false"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{
        'menu-item-active': hasActiveChildren('/complaint-data')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> {{ $t("MENU.COMPLAINT_DATA") }} </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link
                    to="/dashboard"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">
                        {{ $t("MENU.COMPLAINT_DASHBOARD") }}
                      </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    to="/complaint-data/data-consultation"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">
                        {{ $t("MENU.COMPLAINT_CONSULTATION") }}
                      </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    to="/complaint-data/complaint-receipt-data"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">
                        {{ $t("MENU.COMPLAINT_RECEIPT_DATA") }}
                      </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    to="/complaint-data/complaint-data-application"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">
                        {{ $t("MENU.COMPLAINT_DATA_APPLICATION") }}
                      </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li v-if="isMobile()===true"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{
        'menu-item-active': hasActiveChildren('/periodic-data')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> {{ $t("MENU.PERIODIC_DATA") }} </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu">
          <ul class="menu-content">
            <li class="menu-item">
              <router-link
                  to="/periodic-data/actual-data"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a :href="href" class="menu-link menu-heading menu-toggle"
                   :class="[
                          isActive && 'menu-item-active',
                          isExactActive && 'menu-item-active'
                        ]"
                   @click="navigate">
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">
                        {{ $t("MENU.ACTUAL_DATA") }}
                      </span>
                </a>
              </router-link>
              <router-link
                  to="/periodic-data/graph-data"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a :href="href" class="menu-link menu-heading menu-toggle"
                   :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                   @click="navigate">
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">
                        {{ $t("MENU.GRAPH_DATA") }}
                      </span>
                </a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li v-if="isMobile()===false"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{
        'menu-item-active': hasActiveChildren('/periodic-data')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> {{ $t("MENU.PERIODIC_DATA") }} </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link
                    to="/periodic-data/actual-data"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">
                        {{ $t("MENU.ACTUAL_DATA") }}
                      </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    to="/periodic-data/graph-data"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">
                        {{ $t("MENU.GRAPH_DATA") }}
                      </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <router-link
        to="/contact-us"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="click"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> {{ $t("MENU.CONTACT_US") }} </span>
        </a>
      </li>
    </router-link>
    <li v-if="isMobile()===true"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/faq') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> {{ $t("MENU.FAQ") }} </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu">
          <ul class="menu-content">
            <li class="menu-item">
              <router-link
                  to="/faq/general-definition"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
              <a :href="href" class="menu-link menu-heading menu-toggle"
                 :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                 @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">
                        {{ $t("MENU.GENERAL_DEFINITION") }}
                      </span>
              </a>
            </router-link>
            <router-link
                to="/faq/rebuttal-statement"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <a :href="href" class="menu-link menu-heading menu-toggle"
                   :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                   @click="navigate">
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">
                        {{ $t("MENU.REBUTTAL_STATEMENT") }}
                      </span>
                </a>
            </router-link>
            <router-link
                to="/faq/user-guide"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <a :href="href" class="menu-link menu-heading menu-toggle"
                   :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                   @click="navigate">
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">
                        {{ $t("MENU.USER_GUIDE") }}
                      </span>
                </a>
            </router-link>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li v-if="isMobile()===false"
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/faq') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> {{ $t("MENU.FAQ") }} </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link
                    to="/faq/general-definition"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">
                        {{ $t("MENU.GENERAL_DEFINITION") }}
                      </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    to="/faq/rebuttal-statement"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">
                        {{ $t("MENU.REBUTTAL_STATEMENT") }}
                      </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    to="/faq/user-guide"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">
                        {{ $t("MENU.USER_GUIDE") }}
                      </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <router-link
        v-if="this.isAuthenticated"
        to="/survey"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> {{ $t('MENU.SURVEY_DATA') }} </span>
        </a>
      </li>
    </router-link>
    <router-link
        to="/home"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> {{ $t('MENU.DASHBOARD') }} </span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "KTMenu",
  data() {
    return {
      url_survey : process.env.SURVEY_URL
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  computed: mapGetters([
    "isAuthenticated"
  ])
};
</script>
