var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[(_vm.isMobile()===true)?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{
      'menu-item-active': _vm.hasActiveChildren('/complaint-data')
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.COMPLAINT_DATA"))+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu"},[_c('ul',{staticClass:"menu-content"},[_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"menu-link menu-heading menu-toggle",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.COMPLAINT_DASHBOARD"))+" ")])])]}}],null,false,2578391638)}),_c('router-link',{attrs:{"to":"/complaint-data/data-consultation"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"menu-link menu-heading menu-toggle",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.COMPLAINT_CONSULTATION"))+" ")])])]}}],null,false,3052563667)}),_c('router-link',{attrs:{"to":"/complaint-data/complaint-receipt-data"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"menu-link menu-heading menu-toggle",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.COMPLAINT_RECEIPT_DATA"))+" ")])])]}}],null,false,2272212001)}),_c('router-link',{attrs:{"to":"/complaint-data/complaint-data-application"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"menu-link menu-heading menu-toggle",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.COMPLAINT_DATA_APPLICATION"))+" ")])])]}}],null,false,2667276807)})],1)])])])]):_vm._e(),(_vm.isMobile()===false)?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{
      'menu-item-active': _vm.hasActiveChildren('/complaint-data')
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.COMPLAINT_DATA"))+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu"},[_c('ul',{staticClass:"menu-content"},[_c('li',{staticClass:"menu-item"},[_c('ul',{staticClass:"menu-inner"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.COMPLAINT_DASHBOARD"))+" ")])])])]}}],null,false,2221555177)}),_c('router-link',{attrs:{"to":"/complaint-data/data-consultation"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.COMPLAINT_CONSULTATION"))+" ")])])])]}}],null,false,3842284492)}),_c('router-link',{attrs:{"to":"/complaint-data/complaint-receipt-data"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.COMPLAINT_RECEIPT_DATA"))+" ")])])])]}}],null,false,3341348862)}),_c('router-link',{attrs:{"to":"/complaint-data/complaint-data-application"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.COMPLAINT_DATA_APPLICATION"))+" ")])])])]}}],null,false,2640609432)})],1)])])])])]):_vm._e(),(_vm.isMobile()===true)?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{
      'menu-item-active': _vm.hasActiveChildren('/periodic-data')
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.PERIODIC_DATA"))+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu"},[_c('ul',{staticClass:"menu-content"},[_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/periodic-data/actual-data"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"menu-link menu-heading menu-toggle",class:[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ],attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.ACTUAL_DATA"))+" ")])])]}}],null,false,1071893283)}),_c('router-link',{attrs:{"to":"/periodic-data/graph-data"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"menu-link menu-heading menu-toggle",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.GRAPH_DATA"))+" ")])])]}}],null,false,3795270081)})],1)])])])]):_vm._e(),(_vm.isMobile()===false)?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{
      'menu-item-active': _vm.hasActiveChildren('/periodic-data')
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.PERIODIC_DATA"))+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu"},[_c('ul',{staticClass:"menu-content"},[_c('li',{staticClass:"menu-item"},[_c('ul',{staticClass:"menu-inner"},[_c('router-link',{attrs:{"to":"/periodic-data/actual-data"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.ACTUAL_DATA"))+" ")])])])]}}],null,false,1654138044)}),_c('router-link',{attrs:{"to":"/periodic-data/graph-data"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.GRAPH_DATA"))+" ")])])])]}}],null,false,2888856478)})],1)])])])])]):_vm._e(),_c('router-link',{attrs:{"to":"/contact-us"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.CONTACT_US"))+" ")])])])]}}])}),(_vm.isMobile()===true)?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren('/faq') },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.FAQ"))+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu"},[_c('ul',{staticClass:"menu-content"},[_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/faq/general-definition"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"menu-link menu-heading menu-toggle",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.GENERAL_DEFINITION"))+" ")])])]}}],null,false,3749428478)}),_c('router-link',{attrs:{"to":"/faq/rebuttal-statement"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"menu-link menu-heading menu-toggle",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.REBUTTAL_STATEMENT"))+" ")])])]}}],null,false,251850293)}),_c('router-link',{attrs:{"to":"/faq/user-guide"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"menu-link menu-heading menu-toggle",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.USER_GUIDE"))+" ")])])]}}],null,false,2049294198)})],1)])])])]):_vm._e(),(_vm.isMobile()===false)?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren('/faq') },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.FAQ"))+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu"},[_c('ul',{staticClass:"menu-content"},[_c('li',{staticClass:"menu-item"},[_c('ul',{staticClass:"menu-inner"},[_c('router-link',{attrs:{"to":"/faq/general-definition"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.GENERAL_DEFINITION"))+" ")])])])]}}],null,false,3649644033)}),_c('router-link',{attrs:{"to":"/faq/rebuttal-statement"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.REBUTTAL_STATEMENT"))+" ")])])])]}}],null,false,159010922)}),_c('router-link',{attrs:{"to":"/faq/user-guide"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.USER_GUIDE"))+" ")])])])]}}],null,false,685177993)})],1)])])])])]):_vm._e(),(this.isAuthenticated)?_c('router-link',{attrs:{"to":"/survey"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('MENU.SURVEY_DATA'))+" ")])])])]}}],null,false,3243059706)}):_vm._e(),_c('router-link',{attrs:{"to":"/home"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('MENU.DASHBOARD'))+" ")])])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }