<template>
  <!-- begin:: Footer -->
  <div
      class="footer kt-grid__item bgi-position-center-center bgi-size-cover bgi-no-cover"
      id="kt_footer"
      style="background-image: url('/media/bg/bg-2.jpg');"
  >
    <!--begin::Container-->
    <div class="container py-lg-8 py-1">
      <!--begin::Row-->
      <div class="row">
        <!--begin::Col-->
        <div class="col-lg-2 my-lg-0 my-5">
          <div class="pt-2">
            <h4 class="text-white pb-1">
              {{ $t("AUTH.USER_STATISTIC.TITLE") }}
            </h4>
            <div class="d-flex align-items-center mb-5">
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg">
                    <!--begin::Svg Icon-->
                    <inline-svg
                        src="media/svg/icons/Communication/Group.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column font-weight-bold">
                <span
                    href="#"
                    class="text-light text-hover-primary mb-1 font-size-lg"
                >
                  {{ this.$t("AUTH.USER_STATISTIC.TODAY") }}
                </span>
                <span class="text-muted">
                  {{
                    this.$t("AUTH.USER_STATISTIC.DATA", {
                      param1: this.sharedState.auth.userStatistic.today
                    })
                  }}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center mb-5">
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg">
                    <!--begin::Svg Icon-->
                    <inline-svg
                        src="media/svg/icons/Communication/Right.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column font-weight-bold">
                <span
                    href="#"
                    class="text-light text-hover-primary mb-1 font-size-lg"
                >
                  {{ this.$t("AUTH.USER_STATISTIC.YESTERDAY") }}
                </span>
                <span class="text-muted">
                  {{
                    this.$t("AUTH.USER_STATISTIC.DATA", {
                      param1: this.sharedState.auth.userStatistic.yesterday
                    })
                  }}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center mb-10">
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg">
                    <!--begin::Svg Icon-->
                    <inline-svg
                        src="media/svg/icons/Files/Folder.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column font-weight-bold">
                <span
                    href="#"
                    class="text-light text-hover-primary mb-1 font-size-lg"
                >
                  {{ this.$t("AUTH.USER_STATISTIC.YEAR") }}
                </span>
                <span class="text-muted">
                  {{
                    this.$t("AUTH.USER_STATISTIC.DATA", {
                      param1: this.sharedState.auth.userStatistic.thisYear
                    })
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-lg-2 my-lg-0 my-5">
          <!-- <div class="row"> -->
          <div class="pt-1">
            <h4 class="text-dark pb-3">.</h4>
            <div class="d-flex align-items-center mb-5">
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg">
                    <!--begin::Svg Icon-->
                    <inline-svg
                        src="media/svg/icons/Communication/Archive.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column font-weight-bold">
                <span
                    href="#"
                    class="text-light text-hover-primary mb-1 font-size-lg"
                >
                  {{ this.$t("AUTH.USER_STATISTIC.MONTH") }}
                </span>
                <span class="text-muted">
                  {{
                    this.$t("AUTH.USER_STATISTIC.DATA", {
                      param1: this.sharedState.auth.userStatistic.thisMonth
                    })
                  }}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center mb-5">
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg">
                    <!--begin::Svg Icon-->
                    <inline-svg
                        src="media/svg/icons/Code/Git3.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column font-weight-bold">
                <span
                    href="#"
                    class="text-light text-hover-primary mb-1 font-size-lg"
                >
                  {{ this.$t("AUTH.USER_STATISTIC.TOTAL") }}
                </span>
                <span class="text-muted">
                  {{
                    this.$t("AUTH.USER_STATISTIC.DATA", {
                      param1: this.sharedState.auth.userStatistic.total
                    })
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-lg-4 my-lg-0 my-8">

        </div>

        <div class="col-lg-4 my-lg-0 my-8">
          <div class="pt-2" style="float:right;">
            <h4 class="text-white pb-3">
              {{ $t("FOOTER.CONTACT_US") }}
            </h4>
          </div>
          <div class="row" style="float:right;">
            <div class="col-md-12">
              <span class="text-muted">
                <p align="right">
                  Komisi Nasional Hak Asasi Manusia Republik Indonesia <br />
                  Jl. Latuharhary No. 4B, Menteng, Jakarta Pusat, DKI Jakarta
                  10310<br />
                  Tlp : 021-3925230<br />
                  Fax : 021-3925227<br />
                  WA : 081226798880<br />
                  email : dataaduan@komnasham.go.id<br />
                </p>
              </span>
            </div>
          </div>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Container-->
    <!--begin::Container-->
    <div class="separator separator-solid opacity-7"></div>
    <!--end::Container-->
    <!--begin::Container-->
    <div class="container py-8">
      <div
          class="d-flex align-items-center justify-content-between flex-lg-row flex-column"
      >
        <!--begin::Copyright-->
        <div class="d-flex align-items-center order-lg-1 order-2">
          <a
              href="https://www.komnasham.go.id/"
              target="_blank"
              class="text-primary text-hover-primary"
          >
            <span class="text-muted font-weight-bold mx-2">2020-2022 ©</span>
            <span class="text-muted font-weight-bold mx-2"
            >Komisi Nasional Hak Asasi Manusia Republik Indonesia</span
            >
          </a>
        </div>
        <!--end::Copyright-->
        <!--begin::Nav-->
        <div class="d-flex align-items-center order-lg-2 order-1 mb-lg-0 mb-5">
          <!-- <a
            href="http://keenthemes.com/metronic"
            target="_blank"
            class="text-white opacity-55 pl-0 text-hover-primary"
          >
            Purchase Lisence
          </a>
          <a
            href="http://keenthemes.com/metronic"
            target="_blank"
            class="text-white opacity-55 px-6 text-hover-primary"
          >
            Team
          </a>
          <a
            href="http://keenthemes.com/metronic"
            target="_blank"
            class="text-white opacity-55 pr-0 text-hover-primary"
          >
            Contact
          </a> -->
        </div>
        <!--end::Nav-->
      </div>
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooterHome",
  data() {
    return {
      sharedState: this.$store.state
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
